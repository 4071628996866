import type { ErrorName, InternalApiError } from '~~/types/error.types'
import ModernError from 'modern-errors'

/* Error subclasses */
// The prop `isUserError` defines if we send the error to the error manager

export const BaseError = ModernError.subclass('BaseError' satisfies ErrorName, {
  props: {
    isUserError: false,
    detail: undefined as any,
    errorCause: undefined as any,
  } // By default the error is because of the app
})

// Related to the authentification of users
export const AuthError = BaseError.subclass('AuthError' satisfies ErrorName, {
  props: { isUserError: true }
})

// Related to the inputs completed by users
export const InputError = BaseError.subclass('InputError' satisfies ErrorName, {
  props: { isUserError: true }
})

// Related to the external APIs
export const ApiError = BaseError.subclass('ApiError' satisfies ErrorName, {
  props: { isUserError: false }
})

// Related to the operations done on the database
export const DatabaseError = BaseError.subclass('DatabaseError' satisfies ErrorName, {
  props: { isUserError: false }
})

// Related to the format of some data
export const FormatError = BaseError.subclass('FormatError' satisfies ErrorName, {
  props: { isUserError: false }
})

// Related to the inconsistency of data (supposedly impossible cases)
export const InconsistentDataError = BaseError.subclass('InconsistentDataError' satisfies ErrorName, {
  props: { isUserError: false }
})

// Other kind of error
export const UnknownError = BaseError.subclass('UnknownError' satisfies ErrorName, {
  props: { isUserError: false }
})

/* Error codes */

export const AuthErrorCodes = {
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  LOGGED_IN: 'LOGGED_IN',
  NO_ACTIVE_SUBSCRIPTION: 'NO_ACTIVE_SUBSCRIPTION',
  EMAIL_ALREADY_REGISTERED: 'EMAIL_ALREADY_REGISTERED',
  PASSWORD_TOO_SHORT: 'PASSWORD_TOO_SHORT',
  INVALID_SIGNATURE: 'INVALID_SIGNATURE',
  INVALID_INTERNAL_API_KEY: 'INVALID_INTERNAL_API_KEY',
  NO_USER_FOUND: 'NO_USER_FOUND',
} as const

export const InputErrorCodes = {
  NO_PROPER_FILES_UPLOADED: 'NO_PROPER_FILES_UPLOADED',
  NO_BODY_CONTENT: 'NO_BODY_CONTENT',
  UNAUTHORIZED_FILE_TYPE: 'UNAUTHORIZED_FILE_TYPE',
  MISSING_PARAMETERS: 'MISSING_PARAMETERS',
  INVALID_PARAMETERS: 'INVALID_PARAMETERS',
  SAME_NAME_FOR_SOME_FILES: 'SAME_NAME_FOR_SOME_FILES',
  INVALID_PHONENUMBER: 'INVALID_PHONENUMBER',
} as const

export const ApiErrorCodes = {
  EODHD_ERROR: 'EODHD_ERROR',
  EODHD_UNKNOWN_ISIN: 'EODHD_UNKNOWN_ISIN',
  EODHD_UNKNOWN_STOCK: 'EODHD_UNKNOWN_STOCK',
  GURUFOCUS_ERROR: 'GURUFOCUS_ERROR',
  TRADE_REPUBLIC_ERROR: 'TRADE_REPUBLIC_ERROR',
  TRADE_REPUBLIC_TOO_MANY_REQUESTS: 'TRADE_REPUBLIC_TOO_MANY_REQUESTS',
  TRADE_REPUBLIC_LOGIN_UNAUTHORIZED: 'TRADE_REPUBLIC_LOGIN_UNAUTHORIZED',
  TRADE_REPUBLIC_WRONG_2FA_CODE: 'TRADE_REPUBLIC_WRONG_2FA_CODE',
  TRADE_REPUBLIC_LOGIN_ERROR: 'TRADE_REPUBLIC_LOGIN_ERROR',
  TRADE_REPUBLIC_WEBSOCKET_ERROR: 'TRADE_REPUBLIC_WEBSOCKET_ERROR',
  TRADE_REPUBLIC_EVENTS_ERROR: 'TRADE_REPUBLIC_EVENT_ERROR',
  TRADE_REPUBLIC_EVENT_DETAILS_ERROR: 'TRADE_REPUBLIC_EVENT_DETAILS_ERROR',
  TRADE_REPUBLIC_NAV_ERROR: 'TRADE_REPUBLIC_NAV_ERROR',
  TRADE_REPUBLIC_CASH_VALUE_ERROR: 'TRADE_REPUBLIC_CASH_VALUE_ERROR',
  TRADE_REPUBLIC_STOCK_POSITIONS_ERROR: 'TRADE_REPUBLIC_STOCK_POSITIONS_ERROR',
} as const

export const DatabaseErrorCodes = {
  INSERT_IMPOSSIBLE: 'INSERT_IMPOSSIBLE',
  SELECT_IMPOSSIBLE: 'SELECT_IMPOSSIBLE',
  DELETE_IMPOSSIBLE: 'DELETE_IMPOSSIBLE',
  UPDATE_IMPOSSIBLE: 'UPDATE_IMPOSSIBLE',
  SELECT_USER_DIVIDENDS_IMPOSSIBLE: 'SELECT_USER_DIVIDENDS_IMPOSSIBLE',
  INSERT_USER_DIVIDENDS_IMPOSSIBLE: 'INSERT_USER_DIVIDENDS_IMPOSSIBLE',
  SELECT_USER_DIVIDEND_TAXES_IMPOSSIBLE: 'SELECT_USER_DIVIDEND_TAXES_IMPOSSIBLE',
  INSERT_USER_DIVIDEND_TAXES_IMPOSSIBLE: 'INSERT_USER_DIVIDEND_TAXES_IMPOSSIBLE',
  DELETE_USER_DIVIDEND_TAXES_IMPOSSIBLE: 'DELETE_USER_DIVIDEND_TAXES_IMPOSSIBLE',
  DELETE_USER_DIVIDENDS_IMPOSSIBLE: 'DELETE_USER_DIVIDENDS_IMPOSSIBLE',
  SELECT_USER_IB_STATUS_IMPOSSIBLE: 'SELECT_USER_IB_STATUS_IMPOSSIBLE',
  SELECT_USER_ACCOUNTS_IMPOSSIBLE: 'SELECT_USER_ACCOUNTS_IMPOSSIBLE',
  SELECT_STOCKS_IMPOSSIBLE: 'SELECT_STOCKS_IMPOSSIBLE',
  INSERT_STOCKS_IMPOSSIBLE: 'INSERT_STOCKS_IMPOSSIBLE',
  STORAGE_UPLOAD_IMPOSSIBLE: 'STORAGE_UPLOAD_IMPOSSIBLE',
  STORAGE_REMOVE_IMPOSSIBLE: 'STORAGE_REMOVE_IMPOSSIBLE',
  STORAGE_DOWNLOAD_IMPOSSIBLE: 'STORAGE_DOWNLOAD_IMPOSSIBLE',
  STORAGE_LIST_IMPOSSIBLE: 'STORAGE_LIST_IMPOSSIBLE',
  DELETE_USER_IB_SUBACCOUNTS_IMPOSSIBLE: 'DELETE_USER_IB_SUBACCOUNTS_IMPOSSIBLE',
  INSERT_USER_IB_SUBACCOUNTS_IMPOSSIBLE: 'INSERT_USER_IB_SUBACCOUNTS_IMPOSSIBLE',
  CREATE_USER_IMPOSSIBLE: 'CREATE_USER_IMPOSSIBLE',
  SELECT_USER_INFOS_IMPOSSIBLE: 'SELECT_USER_INFOS_IMPOSSIBLE',
  UPDATE_USER_INFOS_IMPOSSIBLE: 'UPDATE_USER_INFOS_IMPOSSIBLE',
  SELECT_CURRENCY_RATES_IMPOSSIBLE: 'SELECT_CURRENCY_RATES_IMPOSSIBLE',
} as const

export const FormatErrorCodes = {
  MISSING_ACCOUNT_NAME: 'MISSING_ACCOUNT_NAME',
  MISSING_BASE_CURRENCY: 'MISSING_BASE_CURRENCY',
  MISSING_COLUMN_NAMES: 'MISSING_COLUMN_NAMES',
  MISSING_TITLE_ELEMENT: 'MISSING_TITLE_ELEMENT',
  MISSING_NET_ASSET_VALUE: 'MISSING_NET_ASSET_VALUE',
  UNKNOWN_NEGATIVE_DIVIDEND: 'UNKNOWN_NEGATIVE_DIVIDEND',
  ZIP_FILE_ERROR: 'ZIP_FILE_ERROR',
  ZIP_FILE_CREATION_ERROR: 'ZIP_FILE_CREATION_ERROR',
  MISSING_PERIOD_DATES: 'MISSING_PERIOD_DATES',
  INVALID_PERIOD_DATES: 'INVALID_PERIOD_DATES',
  UNKNOWN_FILE_LANGUAGE: 'UNKNOWN_FILE_LANGUAGE',
  FILE_DOWNLOAD_IMPOSSIBLE: 'FILE_DOWNLOAD_IMPOSSIBLE',
  POSITIVE_TAX_AMOUNT: 'POSITIVE_TAX_AMOUNT',
  UNKNOWN_GLOBAL_CURRENCY_CODE: 'UNKNOWN_GLOBAL_CURRENCY_CODE',
  TRADE_REPUBLIC_WRONG_EVENT_FORMAT: 'TRADE_REPUBLIC_WRONG_EVENT_FORMAT',
  TRADE_REPUBLIC_BILL_DOCUMENT_ERROR: 'TRADE_REPUBLIC_BILL_DOCUMENT_ERROR',
} as const

export const InconsistentDataErrorCodes = {
  STOCK_SHOULD_EXIST: 'STOCK_SHOULD_EXIST',
  DIVIDEND_SHOULD_EXIST: 'DIVIDEND_SHOULD_EXIST',
  USER_ACCOUNT_SHOULD_EXIST: 'USER_ACCOUNT_SHOULD_EXIST',
  SOLITARY_NEGATIVE_DIVIDEND: 'SOLITARY_NEGATIVE_DIVIDEND',
  SOLITARY_CANCELLED_DIVIDEND_TAX: 'SOLITARY_CANCELLED_DIVIDEND_TAX',
  INCONSISTENT_CHECK_SUM: 'INCONSISTENT_CHECK_SUM',
  UNLINKED_DIVIDEND_TAX: 'UNLINKED_DIVIDEND_TAX',
  NO_CURRENT_TRANSACTION: 'NO_CURRENT_TRANSACTION',
  MULTIPLE_CURRENCY_CODES: 'MULTIPLE_CURRENCY_CODES',
  NO_CURRENCY_RATE_FOR_DATE: 'NO_CURRENCY_RATE_FOR_DATE',
  UNKNOWN_DIVIDEND_TYPE: 'UNKNOWN_DIVIDEND_TYPE',
  NO_FINANCIAL_INSTRUMENT: 'NO_FINANCIAL_INSTRUMENT',
  NO_EODHD_EXCHANGE: 'NO_EODHD_EXCHANGE',
  SAME_STOCK_POSITION_HAS_DIFFERENT_CURRENCY_CODE: 'SAME_STOCK_POSITION_HAS_DIFFERENT_CURRENCY_CODE',
  UNABLE_TO_GET_SPLIT_MULTIPLE: 'UNABLE_TO_GET_SPLIT_MULTIPLE',
} as const

/* Error sentences (in french) */

export const ErrorMessages: Record<ErrorName, Record<string, string>> = {
  BaseError: {
    DEFAULT: `Une erreur basique vient de se produire.`,
  },
  UnknownError: {
    DEFAULT: `Une erreur inconnue semble avoir eu lieu, un développeur doit intervenir.`
  },
  AuthError: {
    DEFAULT: `Une erreur liée à l'authentification vient de se produire.`,
    NOT_LOGGED_IN: `Veuillez vous connecter afin de réaliser cette opération.`,
    LOGGED_IN: `Veuillez vous déconnecter afin de réaliser cette opération.`,
    NO_ACTIVE_SUBSCRIPTION: `Votre email n'est apparemment lié à aucun abonnement actif sur la méthode Trident.`,
    EMAIL_ALREADY_REGISTERED: `Cette email est déjà lié à un compte existant.`,
    PASSWORD_TOO_SHORT: `Le mot de passe doit faire au moins 6 caractères.`,
    INVALID_SIGNATURE: `La signature de la requête est invalide.`,
    INVALID_INTERNAL_API_KEY: `La clé d'API interne est invalide.`,
    NO_USER_FOUND: `Aucun utilisateur n'a été trouvé avec ces identifiants.`,
  } satisfies Record<keyof typeof AuthErrorCodes | 'DEFAULT', string>,
  InputError: {
    DEFAULT: `Une erreur liée au contenu des champs remplis vient de se produire.`,
    NO_PROPER_FILES_UPLOADED: `Les fichiers uploadés ne semblent pas être ceux attendus.`,
    NO_BODY_CONTENT: `Le contenu de la requête est vide.`,
    UNAUTHORIZED_FILE_TYPE: `Un de vos fichiers uploadés ne fait pas parti des types de fichiers acceptés.`,
    MISSING_PARAMETERS: `Certains paramètres obligatoires sont manquants.`,
    INVALID_PARAMETERS: `Certains paramètres sont invalides.`,
    SAME_NAME_FOR_SOME_FILES: `Certains fichiers ont le même nom, attention à ne pas uploader deux fois le même fichier, ou alors à ré-uploader un fichier existant.`,
    INVALID_PHONENUMBER: `Le numéro de téléphone n'est pas valide, il doit commencer par un "+".`,
  } satisfies Record<keyof typeof InputErrorCodes | 'DEFAULT', string>,
  ApiError: {
    DEFAULT: `Une erreur liée à un appel à une API externe vient de se produire.`,
    EODHD_ERROR: `Une erreur est survenue lors de l'appel à notre fournisseurs de données financières, un développeur doit intervenir.`,
    EODHD_UNKNOWN_STOCK: `Une erreur est survenue lors de l'appel à notre fournisseurs de données financières, un développeur doit intervenir.`,
    EODHD_UNKNOWN_ISIN: `L'ISIN d'une action n'a pas pu être trouvé par notre fournisseur de données financières.`,
    GURUFOCUS_ERROR: `Une erreur est survenue lors de l'appel à notre fournisseurs de données financières, un développeur doit intervenir.`,
    TRADE_REPUBLIC_ERROR: `Une erreur est survenue lors de l'appel à l'API de Trade Republic, veuillez réessayer plus tard.`,
    TRADE_REPUBLIC_LOGIN_UNAUTHORIZED: `La connexion à Trade Republic a échoué, veuillez vérifier vos identifiants et réessayer.`,
    TRADE_REPUBLIC_TOO_MANY_REQUESTS: `La connexion à Trade Republic a échoué car il y a eu trop de tentatives de connexion, veuillez réessayer d'ici une minute.`, 
    TRADE_REPUBLIC_LOGIN_ERROR: `La connexion à Trade Republic a échoué, vos identifiants sont peut-être mal formattés.`,
    TRADE_REPUBLIC_WRONG_2FA_CODE: `Le code 2FA est invalide selon TradeRepublic, veuillez réessayer.`,
    TRADE_REPUBLIC_WEBSOCKET_ERROR: `Une erreur est survenue lors de la connexion au websocket de Trade Republic, un développeur doit intervenir.`,
    TRADE_REPUBLIC_EVENTS_ERROR: `Une erreur est survenue lors de la récupération des évènements Trade Republic, un développeur doit intervenir.`,
    TRADE_REPUBLIC_EVENT_DETAILS_ERROR: `Une erreur est survenue lors de la récupération des détails d'un évènement Trade Republic, un développeur doit intervenir.`,
    TRADE_REPUBLIC_NAV_ERROR: `Une erreur est survenue lors de la récupération de la valeur nette d'actif Trade Republic, un développeur doit intervenir.`,
    TRADE_REPUBLIC_CASH_VALUE_ERROR: `Une erreur est survenue lors de la récupération de la valeur en cash de votre compte Trade Republic, un développeur doit intervenir.`,
    TRADE_REPUBLIC_STOCK_POSITIONS_ERROR: `Une erreur est survenue lors de la récupération de vos positions d'actions Trade Republic, un développeur doit intervenir.`,
  } satisfies Record<keyof typeof ApiErrorCodes | 'DEFAULT', string>,
  DatabaseError: {
    DEFAULT: `Une erreur liée à la base de données vient de se produire.`,
    INSERT_IMPOSSIBLE: `Une erreur semble rendre impossible l'ajout de certaines données à la base de données.`,
    SELECT_IMPOSSIBLE: `Une erreur semble rendre impossible la récupération de certaines données en base de données.`,
    DELETE_IMPOSSIBLE: `Une erreur semble rendre impossible la suppression de certaines données en base de données.`,
    UPDATE_IMPOSSIBLE: `Une erreur semble rendre impossible la mise à jour de certaines données en base de données.`,
    SELECT_USER_DIVIDENDS_IMPOSSIBLE: `Une erreur en base de données rend impossible la récupération de vos dividendes.`,
    INSERT_USER_DIVIDENDS_IMPOSSIBLE: `Une erreur en base de données rend impossible l'ajout de vos dividendes.`,
    SELECT_USER_DIVIDEND_TAXES_IMPOSSIBLE: `Une erreur en base de données rend impossible la récupération des taxes de vos dividendes.`,
    INSERT_USER_DIVIDEND_TAXES_IMPOSSIBLE: `Une erreur en base de données rend impossible l'ajout des taxes de vos dividendes.`,
    DELETE_USER_DIVIDEND_TAXES_IMPOSSIBLE: `Une erreur en base de données rend impossible la suppression des taxes liées à vos anciens dividendes.`,
    DELETE_USER_DIVIDENDS_IMPOSSIBLE: `Une erreur en base de données rend impossible la suppression de vos anciens dividendes.`,
    SELECT_USER_IB_STATUS_IMPOSSIBLE: `La récupération du status de votre compte IB semble impossible.`,
    SELECT_USER_ACCOUNTS_IMPOSSIBLE: `La récupération de vos différents comptes IB en base dé données semble impossible.`,
    SELECT_STOCKS_IMPOSSIBLE: `Une erreur en base de données rend impossible la récupération des actions d'entreprise.`,
    INSERT_STOCKS_IMPOSSIBLE: `Une erreur en base de données rend impossible l'ajout de vos actions d'entreprise.`,
    STORAGE_UPLOAD_IMPOSSIBLE: `Une erreur en base de données rend impossible l'enregistrement finaux de vos relevés d'activités, un développeur doit intervenir.`,
    STORAGE_LIST_IMPOSSIBLE: `Une erreur en base de données rend impossible l'accès à vos fichiers, un développeur doit intervenir.`,
    STORAGE_REMOVE_IMPOSSIBLE: `Une erreur en base de données rend impossible la suppresion de vos anciens fichiers, un développeur doit intervenir.`,
    STORAGE_DOWNLOAD_IMPOSSIBLE: `Une erreur en base de données rend impossible le téléchargement de vos fichiers, un développeur doit intervenir.`,
    DELETE_USER_IB_SUBACCOUNTS_IMPOSSIBLE: `Une erreur en base de données rend impossible la suppression de vos anciens sous-comptes IB, un développeur doit intervenir.`,
    INSERT_USER_IB_SUBACCOUNTS_IMPOSSIBLE: `Une erreur en base de données rend impossible l'ajout de vos nouveaux sous-comptes IB, un développeur doit intervenir.`,
    CREATE_USER_IMPOSSIBLE: `Une erreur en base de données rend impossible la création de votre compte, un développeur doit intervenir.`,
    SELECT_USER_INFOS_IMPOSSIBLE: `Une erreur en base de données rend impossible la récupération de vos informations, un développeur doit intervenir.`,
    UPDATE_USER_INFOS_IMPOSSIBLE: `Une erreur en base de données rend impossible la mise à jour de vos informations, un développeur doit intervenir.`,
    SELECT_CURRENCY_RATES_IMPOSSIBLE: `Une erreur en base de données rend impossible la récupération des taux de change, un développeur doit intervenir.`,
  } satisfies Record<keyof typeof DatabaseErrorCodes | 'DEFAULT', string>,
  FormatError: {
    DEFAULT: `Une erreur liée au format d'une donnée vient de se produire.`,
    MISSING_ACCOUNT_NAME: `Le nom du compte IB n'a pas pu être trouvé dans un des fichiers analysés, veuillez vérifier que l'ensemble des fichiers proviennent d'une exportation de votre compte IB.`,
    MISSING_BASE_CURRENCY: `La devise de base n'a pas pu être trouvée dans un des fichiers analysés, veuillez vérifier que l'ensemble des fichiers proviennent d'une exportation de votre compte IB.`,
    MISSING_COLUMN_NAMES: `Certains noms de colonnes dans un des fichiers analysés n'existent pas, veuillez vérifier que l'ensemble des fichiers proviennent d'une exportation de votre compte IB.`,
    MISSING_TITLE_ELEMENT: `Un des fichiers analysés ne contient pas de titre, veuillez vérifier que tous des fichiers proviennent d'une exportation de votre compte IB.`,
    MISSING_NET_ASSET_VALUE: `Un des fichiers analysés ne contient pas de valeur nette d'actif, veuillez vérifier que tous des fichiers proviennent d'une exportation de votre compte IB.`,
    UNKNOWN_NEGATIVE_DIVIDEND: `L'un de vos dividendes avec un montant négatif n'est pas dans un format reconnu par notre système, un développeur doit intervenir.`,
    ZIP_FILE_ERROR: `Une erreur est survenue lors de l'ouverture de votre fichier zip, vérifier que ce fichier est valide et recommencer s'il vous plait.`,
    ZIP_FILE_CREATION_ERROR: `Une erreur est survenue lors de la création de votre fichier zip, un développeur doit intervenir.`,
    MISSING_PERIOD_DATES: `Veuillez vérifier que l'ensemble des fichiers proviennent d'une exportation de votre compte IB, car les dates de la période d'un des fichier analysé n'ont pas pu être trouvées.`,
    INVALID_PERIOD_DATES: `Veuillez vérifier que l'ensemble de vos relevés IB sont en anglais ou français, car les dates de périodes ne sont pas valides dans un des fichiers analysés.`,
    UNKNOWN_FILE_LANGUAGE: `Veuillez vérifier que l'ensemble de vos relevés IB sont en anglais ou français, car la langue d'un des fichiers analysés n'a pas pu être déterminée.`,
    FILE_DOWNLOAD_IMPOSSIBLE: `Une erreur est survenue lors du téléchargement de vos fichiers, veuillez réessayer.`,
    POSITIVE_TAX_AMOUNT: `Un de vos dividendes a un montant de taxe positif, ce qui ne devrait pas être possible, un développeur doit intervenir.`,
    UNKNOWN_GLOBAL_CURRENCY_CODE: `Une devise inconnue a été détectée, un développeur doit intervenir.`,
    TRADE_REPUBLIC_WRONG_EVENT_FORMAT: `Le format d'une donnée sur TradeRepublic n'est pas reconnu, un développeur doit intervenir.`,
    TRADE_REPUBLIC_BILL_DOCUMENT_ERROR: `Une erreur est survenue lors de la récupération d'un document de facturation Trade Republic, un développeur doit intervenir.`,
  } satisfies Record<keyof typeof FormatErrorCodes | 'DEFAULT', string>,
  InconsistentDataError: {
    DEFAULT: `Une erreur liée à l'incohérence des données vient de se produire.`,
    STOCK_SHOULD_EXIST: `Une des actions gérée n'est pas présente en base de données, ce qui ne devrait même pas être possible, un développeur doit intervenir.`,
    DIVIDEND_SHOULD_EXIST: `Un des dividendes n'est pas présent en base de données, ce qui ne devrait même pas être possible, un développeur doit intervenir.`,
    USER_ACCOUNT_SHOULD_EXIST: `Un de vos dividendes n'est lié à aucun comptes IB détectés, ce qui ne devrait même pas possible, un développeur doit intervenir.`,
    SOLITARY_NEGATIVE_DIVIDEND: `Veuillez vérifier que vous avez uploadé tous vos relevés annuels en même temps, car une incohérence a été détectée, mais celle-ci est résolvable avec plus de données (SOLITARY_NEGATIVE_DIVIDEND).`,
    SOLITARY_CANCELLED_DIVIDEND_TAX: `Veuillez vérifier que vous avez uploadé tous vos relevés annuels en même temps, car une incohérence a été détectée, mais celle-ci est résolvable avec plus de données (SOLITARY_CANCELLED_DIVIDEND_TAX).`,
    INCONSISTENT_CHECK_SUM: `Il est possible que vous ayez uploadé deux fois le même fichier, car la somme des dividendes analysés ne correspond pas à la somme écrite sur vos relevés.`,
    UNLINKED_DIVIDEND_TAX: `Veuillez vérifier que vous avez uploadé tous vos relevés annuels en même temps, car une incohérence a été détectée, mais celle-ci est résolvable avec plus de données (UNLINKED_DIVIDEND_TAX).`,
    NO_CURRENT_TRANSACTION: `Une erreur d'incohérence a eu lieu sur le gestionnaire des erreurs lui-même (aucune transaction).`,
    MULTIPLE_CURRENCY_CODES: `Plusieurs devises ont été détecté et ne sont pas prises en compte, un développeur doit intervenir.`,	
    NO_CURRENCY_RATE_FOR_DATE: `Aucun taux de change n'a été trouvé pour une des devises utilisées, un développeur doit intervenir.`,
    UNKNOWN_DIVIDEND_TYPE: `Un type de dividende inconnu a été détecté, un développeur doit intervenir.`,
    NO_FINANCIAL_INSTRUMENT: `Un des dividendes n'est lié à aucun instrument financier, ce qui ne devrait pas être possible, un développeur doit intervenir.`,
    NO_EODHD_EXCHANGE: `Un des dividendes n'est lié à aucun marché, ce qui ne devrait pas être possible, un développeur doit intervenir.`,
    SAME_STOCK_POSITION_HAS_DIFFERENT_CURRENCY_CODE: `Une incohérence a été détectée, car une même position d'actions a deux devises différents, un développeur doit intervenir.`,
    UNABLE_TO_GET_SPLIT_MULTIPLE: `Le multiple d'un split n'a pas pu être déterminé, un développeur doit intervenir.`,
  } satisfies Record<keyof typeof InconsistentDataErrorCodes | 'DEFAULT', string>,
}

/**
 * Return the proper error message
 *
 * @export
 * @param {ErrorName} errorName
 * @param {string} [errorCode='DEFAULT']
 * @return {*} 
 */
export function getErrorMessage(errorName: ErrorName, errorCode: string = 'DEFAULT', detail?: any): string {
  const detailText = detail ? ` (${detail})` : ''
  if (errorName in ErrorMessages && errorCode in ErrorMessages[errorName]) {
    return ErrorMessages[errorName][errorCode] + detailText
  } else {
    return `${ErrorMessages['UnknownError']['DEFAULT']}${detailText} (${errorName}: ${errorCode})`
  }
}

/**
 * Return the proper error message from an ApiError
 *
 * @export
 * @param {InternalApiError} apiError
 * @return {*}  {string}
 */
export function getErrorMessageFromApiError(apiError: InternalApiError): string {
  console.log(apiError.statusMessage)
  if (apiError.statusMessage === 'BaseError') {
    console.log(apiError.data)
    return getErrorMessage(apiError.data.errorName, apiError.data.errorCode, apiError.data.detail)
  } else {
    return getErrorMessage('UnknownError')
  }
}

/**
 * Return true if the error is an ApiError
 *
 * @export
 * @param {*} error
 * @return {*}  {error is ApiError}
 */
export function isApiError(apiError: any): apiError is InternalApiError {
  return apiError && apiError.data && apiError.statusMessage
}